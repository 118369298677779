// extracted by mini-css-extract-plugin
export var containerMain = "contact-module--containerMain--c37f2";
export var containerCenter = "contact-module--containerCenter--46287";
export var card = "contact-module--card--5f44a";
export var card2 = "contact-module--card2--fe8f2";
export var card3 = "contact-module--card3--96411";
export var card4 = "contact-module--card4--d9947";
export var card5 = "contact-module--card5--0fc0e";
export var invisiblecard = "contact-module--invisiblecard--73490";
export var profileImage = "contact-module--profileImage--58a0c";
export var invisiblecard_wide = "contact-module--invisiblecard_wide--bd25f";
export var invisiblecard_thin = "contact-module--invisiblecard_thin--5b1fa";
export var card3_longmailfix = "contact-module--card3_longmailfix--62d3b";